import React, {createContext} from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Store from './core/store';
import { BrowserRouter } from 'react-router-dom';
import AdminStore from './core/store/admin';

import { IntlProvider } from 'react-intl';
import { Translation } from './core/models/Translate';
import { LOCALES } from './core/i18n/locales';

interface State {
  store: Store,
  adminStore: AdminStore
}

const store = new Store()
const adminStore = new AdminStore()

export const Context = createContext<State>({store, adminStore})

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <Context.Provider value={{store, adminStore}}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Context.Provider>
  </React.StrictMode>
);

reportWebVitals();
