import { makeAutoObservable } from "mobx";
import { LOCALES } from "../i18n/locales";
import {XImage} from "../models/XImage";
import {IXProject} from "../models/XProject";
import {HomeMenuPage, StorageKeys} from "../utils/defaults";

export default class Store {

  isAuth = false;
  token: string = "";
  userData: any = {};
  locale: string = LOCALES.RUSSIAN;
  theme: string = "winter"

  isActiveA: boolean = false;
  portfolioRendered: boolean = false;

  isBackCallVisible: boolean = false;
  isMobile: boolean = false;
  isMenuOpenned: boolean = false;
  isFaderVisible: boolean = true;
  isFaderShown: boolean = true;
  isFaderLogo: boolean = false;
  isContentShown: boolean = false;
  isHomeBlockVisible: boolean = false;

  xScr: number = 50

  isHeaderLocked: boolean = false;
  selectedSlide: number = 0;

  menuSelected: number = 0;

  refuseDrop: boolean = false;

  selectedIx: number = 0;
  selectedIxSPA: number = 0;
  selectedIxResto: number = 0;
  selectedIxEntert: number = 0;
  allRooms: any[] = [];
  allSPA: any[] = [];
  allResto: any[] = [];
  allEntert: any[] = [];
  allEntertItems: any[] = [];

  isImageViewVisible: boolean = false;
  selectedImages: XImage[] = [];
  selectedImageIndex: number = 0;

  videoInit: number = 0;
  
  nextProject: IXProject | null = null
  selectedProject: IXProject | null = null
  selectedProjectImgs: any[] = []

  projectTiles: number = 0
  projectTilts: number = 0

  isSlideActive: boolean = true
  isSliderInited: boolean = false
  slideIndex: number = 0

  mouseEntered: boolean = false

  activeSl: string = ""

  formTitle: string = ""
  isFormVisible: boolean = false

  constructor() {
    makeAutoObservable(this);
  }

  setFormTitle(val: string) {
    this.formTitle = val
  }

  setIsFormVisible(val: boolean) {
    this.isFormVisible = val
  }

  setActiveSl(sl: string) {
    this.activeSl = sl
  }

  setMouseEntered(val: boolean) {
    this.mouseEntered = val
  }

  setIsSliderInited(val: boolean) {
    this.isSliderInited = val
  }

  setIsSlideActive(val: boolean) {
    this.isSlideActive = val
  }

  setSlideIndex(val: number) {
    this.slideIndex = val
  }

  setProjectTilts(val: number) {
    this.projectTilts = val
  }

  setProjectTiles(val: number) {
    this.projectTiles = val
  }

  setLocale(val: any) {
    localStorage.setItem("mainburo_lang", val)
    this.locale = val
  }

  setSelectedProject(val: IXProject | null) {
    this.selectedProject = val
  }

  setNextProject(val: IXProject | null) {
    this.nextProject = val
  }

  setSelectedProjectImgs(val: any) {
    this.selectedProjectImgs = val
  }

  setTheme(val: string) {
    this.theme = val
  }

  setVideoInit(val: number) {
    this.videoInit = val
  }

  setIsImageViewVisible(val: boolean) {
    this.isImageViewVisible = val;
  }

  setSelectedImageIndex(val: number) {
    this.selectedImageIndex = val;
  }

  setSelectedImages(val: XImage[]) {
    this.selectedImages = val;
  }
  
  setAllRooms(val: any[]) {
    this.allRooms = val;
  }

  setAllSPA(val: any[]) {
    this.allSPA = val;
  }

  setAllEntert(val: any[]) {
    this.allEntert = val;
  }

  setAllEntertItems(val: any[]) {
    this.allEntertItems = val;
  }

  setSelectedIxEntert(val: number) {
    this.selectedIxEntert = val;
  }

  setAllResto(val: any[]) {
    this.allResto = val;
  }

  setSelectedIxResto(val: number) {
    this.selectedIxResto = val;
  }

  setSelectedIxSPA(val: number) {
    this.selectedIxSPA = val;
  }

  setSelectedIx(val: number) {
    this.selectedIx = val;
  }

  setRefuseDrop(bl: boolean) {
    this.refuseDrop = bl;
  }

  setSelectedSlide(bl: number) {
    this.selectedSlide = bl;
  }

  setIsHomeBlockVisible(bl: boolean) {
    this.isHomeBlockVisible = bl;
  }

  setMenuSelected(item: number) {
    this.menuSelected = item;
  }

  setIsHeaderLocked(bl: boolean) {
    this.isHeaderLocked = bl;
  }

  setIsBackCallVisible(bl: boolean) {
    this.isBackCallVisible = bl;
  }

  setIsContentShown(bl: boolean) {
    this.isContentShown = bl;
  }

  setIsMobile(bl: boolean) {
    this.isMobile = bl;
  }

  setIsMenuOpenned(bl: boolean) {
    this.isMenuOpenned = bl;
  }

  setIsFaderVisible(bl: boolean) {
    this.isFaderVisible = bl;
  }

  setIsFaderShown(bl: boolean) {
    this.isFaderShown = bl;
  }

  setIsFaderLogo(bl: boolean) {
    this.isFaderLogo = bl
  }

  checkAuth() {
    const authed = localStorage.getItem(StorageKeys.AUTHED)
    if (authed != null && authed == "1") {
      const user = localStorage.getItem(StorageKeys.USER)
      const token = localStorage.getItem(StorageKeys.TOKEN)
      if (user != null && token != null) {
        this.isAuth = true
        this.userData = JSON.parse(user)
        this.token = token
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }

  applyAuth(token: string, user: any) {
    localStorage.setItem(StorageKeys.AUTHED, "1")
    localStorage.setItem(StorageKeys.TOKEN, token)
    localStorage.setItem(StorageKeys.USER, JSON.stringify(user))

    this.token = token
    this.userData = user
    this.isAuth = true
  }

  logout() {
    localStorage.clear()
    this.token = ""
    this.userData = null
    this.isAuth = false
  }

}
