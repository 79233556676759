import { Menu, MenuProps } from "antd"
import { observer } from "mobx-react-lite"
import { FC, useContext, useEffect, useState } from "react"
import {FormattedMessage} from "react-intl"
import { useLocation, useNavigate } from "react-router-dom"
import { Context } from ".."
import useWindowDimensions from "../core/hooks/useWindowDimensions"
import {LOCALES} from "../core/i18n/locales"
import { isNullOrUndefined, translit } from "../core/utils/utils"

import styles from "../styles/Header.module.scss"

interface HeaderProps {
  isMobile?: boolean
  onOpenMenu?: any
  goToSection?: any
  onBackCall?: any
}

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group',
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

// const items: MenuProps['items'] = [
//   getItem('Navigation Two', 'sub2', null, [
//     getItem('Option 5', '5'),
//     getItem('Option 6', '6'),
//   ]),

//   getItem('Navigation Three', 'sub4', null, [
//     getItem('Option 9', '9'),
//     getItem('Option 10', '10'),
//     getItem('Option 11', '11'),
//     getItem('Option 12', '12'),
//   ])
// ];

const Header: FC<HeaderProps> = ({
  onOpenMenu = null, goToSection = null, onBackCall = null, isMobile = false
}) => {

  const { store, adminStore } = useContext(Context)
  const { width, height } = useWindowDimensions()
  const navigate = useNavigate()
  const location = useLocation()

  const [headerToggled, setHeaderToggled] = useState(true)
  const [headerTgl, setHeaderTgl] = useState(false)

  const [isMenuShown, setIsMenuShown] = useState(false)
  const [isMenuVisible, setIsMenuVisible] = useState(false)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  
  const [items, setItems] = useState([] as MenuProps['items'])

  const onMouseUp = (e: any) => {
    var container = document.getElementById("ddp_menu")

    console.log("PRE-FIRREEEE", e)
    if (container != null && !container.contains(e.target)) {
      //container.style.display = 'none'
      console.log("FIRREEEE!!!", e)
      closeMenu()
    }
  }

  const openMenu = () => {
    setIsMenuShown(prev => prev = true)
    // if (width < 1280) {
      document.getElementById("root")!.style.overflowY = "hidden"
      document.getElementsByTagName("html")[0].style.overflowY = "hidden"
      document.body.style.overflowY = "hidden"

      document.addEventListener('mouseup', onMouseUp)
    // }
    setTimeout(() => {
      setIsMenuVisible(prev => prev = true)
      setTimeout(() => {
        setIsMenuOpen(prev => prev = true)
      }, 300)
    }, 100)
  }

  const closeMenu = () => {
    setIsMenuVisible(prev => prev = false)
    setIsMenuOpen(prev => prev = false)
    // if (width < 1280) {
      document.getElementById("root")!.style.overflowY = "scroll"
      document.getElementsByTagName("html")[0].style.overflowY = "hidden"
      document.body.style.overflowY = "hidden"

      document.removeEventListener('mouseup', onMouseUp)
    // }
    setTimeout(() => {
      setIsMenuShown(prev => prev = false);
    }, 300);
  }

  const goTop = () => {
    if (store.menuSelected != 0) {
      store.setIsHomeBlockVisible(false);
      store.setIsHeaderLocked(false);
      store.setSelectedSlide(0);
      setTimeout(() => {
        store.setMenuSelected(0);
        setTimeout(() => {
          store.setIsHomeBlockVisible(true);
        }, 100);
      }, 200);
    }

    const section = document.querySelector(`#top`);
    if (!isNullOrUndefined(section)) {
      section.scrollIntoView({
        behavior: 'smooth'
      });
    }
  }

  const navTo = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    
    let path = "";

    if (event.target.nodeName.toLowerCase() == "img") {
      path = event.target.parentElement.pathname;
    } else {
      path = event.target.pathname;
    }

    console.log(path, location.pathname, event);

    if (path == location.pathname) {
      return
    }

    store.setIsFaderVisible(true)
    setTimeout(() => {
      store.setIsFaderShown(true)

      window.scroll(0, 0)

      setTimeout(() => {
        navigate(path)
      }, 300)
    }, 100)
  }

  const isHeaderBg = () => {
    return location.pathname == "/contacts" || location.pathname == "/about"
  }

  const runAnimations = () => {
    const logo = document.getElementById("logo")
    const open = document.getElementById("open")
    const menu1 = document.getElementById("menu1")
    const menu2 = document.getElementById("menu2")
    const menu3 = document.getElementById("menu3")
    const menu4 = document.getElementById("menu4")
    const menu5 = document.getElementById("menu5")
    const soc1 = document.getElementById("soc1")
    const soc2 = document.getElementById("soc2")
    const act1 = document.getElementById("act1")

    if (logo != null) {
      logo.style.opacity = "1"
    }

    if (open != null) {
      setTimeout(() => {
        open.style.opacity = "1"
      }, 200)
    }

    if (
      menu1 != null &&
      menu2 != null &&
      menu3 != null &&
      menu4 != null &&
      menu5 != null
    ) {
      setTimeout(() => {
        menu1.style.opacity = "1"
        setTimeout(() => {
          menu2.style.opacity = "1"
          setTimeout(() => {
            menu3.style.opacity = "1"
            setTimeout(() => {
              menu4.style.opacity = "1"
              setTimeout(() => {
                menu5.style.opacity = "1"
              }, 60)
            }, 60)
          }, 60)
        }, 60)
      }, 100)
    }

    if (soc1 != null && soc2 != null && act1 != null) {
      act1.style.opacity = "1"
      setTimeout(() => {
        soc1.style.opacity = "1"
        setTimeout(() => {
          soc2.style.opacity = "1"
        }, 100)
      }, 100)
    }
  }

  const openForm = (title: string) => {
    store.setFormTitle(title)
    store.setIsFormVisible(true)
    closeMenu()
  }

  useEffect(() => {
    let scrFix = 0;

    setTimeout(() => {
      runAnimations()
    }, 100)

    const onScroll = (event: any) => {
      const scrollTop = event.target.scrollTop
      
      // if (width <= 768 && location.pathname == "/") {
      //   return
      // }

      if (scrollTop >= 60) {
        store.setIsHeaderLocked(true)
      } else {
        store.setIsHeaderLocked(false)
      }

      console.log(store.xScr)

      if (scrollTop < scrFix && scrollTop > store.xScr) {
        setHeaderToggled(prev => prev = true)
      } else if (scrollTop > store.xScr) {
        setHeaderToggled(prev => prev = false)
      }

      scrFix = parseInt(`${scrollTop}`);
      
      if (location.pathname.includes("/projects") && store.selectedProject != null) {
        if (scrollTop > window.innerHeight - 50) {
          setHeaderTgl(prev => prev = true)
        } else {
          setHeaderTgl(prev => prev = false)
        }
      } else {
        setHeaderTgl(prev => prev = false)
      }
    }
    
    document.getElementById("root")!.addEventListener('scroll', onScroll)

    return () => {
      document.getElementById("root")!.removeEventListener('scroll', onScroll)
    }
  }, [])

  return (
    <>
      <header className={styles.header + (store.isHeaderLocked ? "" : " " + styles.noblur) + (!headerToggled && !isMenuShown ? " " + styles.fixed : "") + (headerTgl ? " " + styles.tgl : "") + (store.isHeaderLocked ? " " + styles.white : "") + (location.pathname.includes("/projects") && !headerTgl && store.selectedProject != null ? " " : "")} style={{
        backgroundColor: isHeaderBg() ? "#7F7F7F" : "#7F7F7F"
      }}>
        <div className="container">
          <div className="row">
            <div className={(width > 1280 ? "col-2 " + styles.ending : "col-6 ")}>
              <div className={styles.logo}><a href="/"><img id="logo" style={{opacity: 0}} src={!headerTgl && location.pathname.includes("/projects") && store.selectedProject != null ? `/assets/images/logo.svg` : `/assets/images/logo.svg`} /></a></div>
            </div>
            <div className={"col-6 " + styles.ending} style={{display: width > 1280 ? "none" : "flex"}}>
              <div id="open" style={{opacity: 0}} className={styles.mn_dp_open + (isMenuVisible ? " " + styles.openned : "")} onClick={() => isMenuShown ? closeMenu() : openMenu()}>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M20 6.3501H0" stroke="white" stroke-width="1.5"/>
                  <path d="M20 12.6001H0" stroke="white" stroke-width="1.5"/>
                </svg>
              </div>
            </div>
            <div className={"col-6 " + styles.ending} style={{display: width > 1280 ? "flex" : "none"}}>
              <div className={styles.menu}>
                <a href="#about" className={styles.menu_item} id="menu1" style={{opacity: 0}}>О франшизе</a>
                <a href="#about_us" className={styles.menu_item} id="menu2" style={{opacity: 0}}>О нас</a>
                <a href="#features" className={styles.menu_item} id="menu3" style={{opacity: 0}}>Преимущества</a>
                <a href="#steps" className={styles.menu_item} id="menu4" style={{opacity: 0}}>Этапы</a>
                <a href="#contacts" className={styles.menu_item} id="menu5" style={{opacity: 0}}>Контакты</a>
              </div>
            </div>
            <div className={"col-4 " + styles.ending} style={{display: width > 1280 ? "flex" : "none"}}>
              <div className={styles.contact}>
                <div>
                  <a href="tel:+79649293011" id="soc1" style={{opacity: 0}}>+7 964 929-30-11</a>
                  <a href="mailto:fermstory@gmail.com" id="soc2" style={{opacity: 0}}>fermstory@gmail.com</a>
                </div>
                <div>
                  <button id="act1" style={{opacity: 0}} onClick={() => openForm("Заказать звонок")}>Заказать звонок</button>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div id="ddp_menu" className={styles.drop_menu + (isMenuOpen ? " " + styles.openned : "")} style={width > 768 ? {height: !isMenuVisible ? "0px" : "266px"} : {right: !isMenuVisible ? "-100%" : "0%"}}>
                <div className={styles.mask}></div>
                <div className={styles.dm_items + (!adminStore.transActive ? " " + styles.mp : "")}>
                  <a href="#about" onClick={() => closeMenu()}>О франшизе</a>
                  <a href="#about_us" onClick={() => closeMenu()}>О нас</a>
                  <a href="#features" onClick={() => closeMenu()}>Преимущества</a>
                  <a href="#steps" onClick={() => closeMenu()}>Этапы</a>
                  <a href="#contacts" onClick={() => closeMenu()}>Контакты</a>
                </div>
                <div className={styles.dm_langs}>
                  <div className={styles.contact}>
                    <div>
                      <a href="tel:+79649293011">+7 964 929-30-11</a>
                      <a href="mailto:fermstory@gmail.com" target="_blank">fermstory@gmail.com</a>
                    </div>
                    <div>
                      <button onClick={() => openForm("Заказать звонок")}>Заказать звонок</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  )
}

export default observer(Header)