import { observer } from "mobx-react-lite"
import { FC, useContext, useEffect, useRef, useState } from "react"
import ImageSlider from "../components/ImageSlider"
import { Context } from ".."

import styles from "../styles/Home.module.scss"
import useWindowDimensions from "../core/hooks/useWindowDimensions"
import { useFetching } from "../core/hooks/useFetching"
import { ApiService } from "../core/api/api"
import { YMaps, Map, ObjectManager, Clusterer, Placemark, ZoomControl } from '@pbe/react-yandex-maps'

import InputMask from 'react-input-mask'

import ReCAPTCHA from "react-google-recaptcha"

import { Pagination, Navigation } from 'swiper';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import { Swiper, SwiperSlide } from 'swiper/react';
import Modal from "../components/Modal"
import {isElementInViewport} from "../core/utils/utils"
import {message} from "antd"

interface IArticle {
  id?: number
  image: string | null
  frame?: string
  title: string
  subtitle: string
  text: string
  source: string | null
  link: string | null
}

const ArticlesData: IArticle[] = [
  {
    id: 1,
    image: "/assets/images/home/fi-story2.jpg",
    title: "Фермерские товары как бизнес",
    subtitle: "Интервью с основателем магазина «Фермерские истории» для канала «Франшизы партнеркин»",
    text: "Предприниматели Краснодарского края решили объединить на одной площадке фермерские товары с ближайших хуторов и деревень, давая возможность развития малому предпринимательству.<br/><br/>Как открыть фермерскую лавку которая не закроется через год, какие товары пользуются наибольшим спросом и сколько можно заработать продавая натуральную продукцию?",
    source: "«Партнеркин Франшизы»",
    link: "https://f.partnerkin.com/blog/interview/fermerskie_tovari"
  }, {
    id: 2,
    image: "/assets/images/home/st1-2.jpg",
    title: "Фермерские лавки - актуальный торговый формат магазинов фермерских продуктов",
    subtitle: 'Статья про сеть магазинов "Фермерские истории"',
    text: "Спрос на натуральные продукты от семейных хозяйств и крафтовых производителей стабильно растет год от года. Все больше потребителей при покупке товаров первой необходимости ставят во главу угла факторы их отменного качества, свежести и экологичности. С учетом этого особую актуальность приобрел такой торговый формат, как фермерские лавки с широким ассортиментом продукции, имеющей прозрачную историю происхождения",
    source: "Журнал «Точка продаж» №124, Май 2021",
    link: "https://tpmag.ru/media/flip/tp124/#p=32"
  }, {
    id: 3,
    image: null,
    frame: `https://www.youtube.com/embed/KCogKCNJSn8?si=d1zRWomNmLU5o2tE`,
    title: "Фермерские продукты. Бизнес курс",
    subtitle: "Интервью на канале Телеканал «Краснодар»",
    text: "Жить на Кубани и не задуматься о фермерском бизнесе — как минимум странно. Черешня, клубника, картошка, домашний мед и яйца… Все это мы производим, а хочется еще и продавать. Так поступил герой очередного выпуска программы «Бизнес-курс» Виталий. Сначала он открыл торговую точку, чтобы реализовать продукцию с фермы своих родителей, а потом привлек к этому бизнесу соседей и организовал торговую сеть. Если вы думаете, что это было очень просто – вы ошибаетесь. Как построить бизнес на том, что у нас с вами лежит под ногами, смотрите в новом выпуске программы «Бизнес-курс».",
    source: null,
    link: null
  }
]

const Home: FC = () => {

  const { store, adminStore } = useContext(Context)
  const { width } = useWindowDimensions()

  const swiperEl1 = useRef(null as any)
  const swiperEl2 = useRef(null as any)
  const swiperEl3 = useRef(null as any)

  const [swiper1, setSwiper1] = useState({} as any)
  const [swiper2, setSwiper2] = useState({} as any)
  const [swiper3, setSwiper3] = useState({} as any)

  const [userName, setUserName] = useState("")
  const [userCity, setUserCity] = useState("")
  const [userPhone, setUserPhone] = useState("")

  const [userToken, setUserToken] = useState("")
  
  const [clusterPoints, setClusterPoints] = useState([
    [55.686852, 37.852365], [47.232634, 39.625379], [45.119326, 38.927496], [45.097692, 38.973570]
  ] as number[][])

  const [articles, setArticles] = useState([] as IArticle[])

  const [fetchData, isDataLoading, errorData] = useFetching(async () => {
    let lst: IArticle[] = []

    lst = ArticlesData

    setArticles(prev => prev = lst)
  })

  const sendForm = async () => {
    if (userToken == "") {
      message.error("Подтвердите капчу!")
      return
    }

    if (userName == "") {
      message.error("Укажите Ваше имя!")
      return
    }

    if (userCity == "") {
      message.error("Укажите Ваш город!")
      return
    }

    if (userPhone == "") {
      message.error("Укажите Ваш номер телефона!")
      return
    }

    store.setIsFormVisible(false)

    const res = await ApiService.core.createMail({
      to_email: "belinskaya@topgun.ru",
      subject: "Заявка с сайта Франшизы",
      message: `<p>С сайта fermstory-franch.ru пришла новая заявка на звонок:<br><br>
      <b>Имя:</b> ${userName}<br>
      <b>Email:</b> ${userCity}<br>
      <b>Телефон:</b> ${userPhone}<br></p>`
    })

    const res2 = await ApiService.core.createMail({
      to_email: "teromekim@gmail.com",
      subject: "Заявка с сайта Франшизы",
      message: `<p>С сайта fermstory-franch.ru пришла новая заявка на звонок:<br><br>
      <b>Имя:</b> ${userName}<br>
      <b>Email:</b> ${userCity}<br>
      <b>Телефон:</b> ${userPhone}<br></p>`
    })

    const res3 = await ApiService.core.createAmoTicket({
      name: userName,
      email: userCity,
      phone: userPhone,
      utm: ""
    })

    console.log(res3)

    setUserName("")
    setUserCity("")
    setUserPhone("")
    setUserToken("")

    message.success("Заявка успешно отправлена!")
  }

  const runAnimations = () => {
    const float_block = document.getElementById("float_block")
    const fb_ttl1 = document.getElementById("fb_ttl1")
    const fb_ttl2 = document.getElementById("fb_ttl2")
    const fb_act = document.getElementById("fb_act")
    
    const bpoint1 = document.getElementById("bpoint1")
    const bpoint2 = document.getElementById("bpoint2")
    const bpoint3 = document.getElementById("bpoint3")
    const bpoint4 = document.getElementById("bpoint4")
    const bpointp = document.getElementById("bpointp")

    if (
      float_block != null && 
      fb_ttl1 != null && 
      fb_ttl2 != null && 
      fb_act != null
    ) {
      float_block.style.borderRadius = "0 100px"
      fb_ttl1.style.opacity = "1"
      fb_ttl1.style.bottom = "0px"
      setTimeout(() => {
        fb_ttl2.style.opacity = "1"

        setTimeout(() => {
          fb_act.style.opacity = "1"
        }, 100)
      }, 100)
    }

    if (
      bpointp != null &&
      bpoint1 != null &&
      bpoint2 != null &&
      bpoint3 != null &&
      bpoint4 != null
    ) {
      bpoint1.style.opacity = "1"
      bpoint1.style.bottom = "0px"
      setTimeout(() => {
        bpoint2.style.opacity = "1"
        bpoint2.style.bottom = "0px"
        setTimeout(() => {
          bpoint3.style.opacity = "1"
          bpoint3.style.bottom = "0px"
          setTimeout(() => {
            bpoint4.style.opacity = "1"
            bpoint4.style.bottom = "0px"
            setTimeout(() => {
              bpointp.style.opacity = "1"
            }, 50)
          }, 100)
        }, 100)
      }, 100)
    }
  }

  const openForm = (title: string) => {
    store.setFormTitle(title)
    store.setIsFormVisible(true)
  }

  const runOpacity = (el: HTMLElement | null, delay: number = 0, asc: number = 0) => {
    if (el != null) {
      // if (width <= 960) {
      //   asc = -40;
      // }

      if (el.style.opacity == "") {
        el.style.opacity = `0`;
      }

      if (isElementInViewport(el)) {
        let dell = window.innerHeight - ((el.getBoundingClientRect().y + el.getBoundingClientRect().height) - delay + asc);
        let spl = dell / 100;  
        
        if (el.style.opacity == "") {
          el.style.opacity = `${spl}`;
        } else {
          
          el.style.opacity = `${spl}`;
        }
      } else {
        if (parseInt(el.style.opacity) == 0) {
          el.style.opacity = `0`;
        }
      }
    }
  }

  const runBottomOpacity = (el: HTMLElement | null, delay: number = 0, asc: number = 0) => {
    if (el != null) {
      // if (width <= 960) {
      //   delay = 40;
      // }

      if (el.style.transform == "") {
        el.style.transform = "translateY(35px)";
      }

      if (el.style.opacity == "") {
        el.style.opacity = "0";
      }

      if (isElementInViewport(el)) {
        let dell = window.innerHeight - ((el.getBoundingClientRect().y + el.getBoundingClientRect().height) - delay + asc);
        let spl = (dell) / 100;
        if (spl > parseInt(el.style.opacity)) {
        }
        el.style.opacity = `${spl}`;
        
        let ix = ((dell) / 4) - 35;

        if (ix > parseInt(el.style.bottom.replace("px", ""))) {
          
        }
        if (ix < 0) {
          el.style.transform = `translateY(${-ix}px)`;
        } else {
          el.style.transform = `translateY(0px)`;
        }
      } else {
        if (el.style.opacity == "0") {
          el.style.opacity = `0`;
          el.style.transform = "translateY(35px)";
        }
      }
    }
  }
  
  useEffect(() => {

    fetchData()

    setTimeout(() => {
      runAnimations()
    }, 400)

    const onScroll = (event: any) => {
      const scrollTop = event.target.scrollTop

      const about1 = document.getElementById("about1")
      const about2 = document.getElementById("about2")
      const about3 = document.getElementById("about3")
      const abit1 = document.getElementById("abit1")
      const abit2 = document.getElementById("abit2")
      const abit3 = document.getElementById("abit3")
      const abit4 = document.getElementById("abit4")
      const about_act = document.getElementById("about_act")
      
      runBottomOpacity(about1)
      runOpacity(about2, -100)
      runOpacity(about3)

      runBottomOpacity(abit1, 0, -200)
      runBottomOpacity(abit2, 0, -180)
      runBottomOpacity(abit3, 0, -160)
      runBottomOpacity(abit4, 0, -140)

      runOpacity(about_act)

      const feat1 = document.getElementById("feat1")
      const feat2 = document.getElementById("feat2")
      const featb1 = document.getElementById("featb1")
      const featb2 = document.getElementById("featb2")
      const featb3 = document.getElementById("featb3")
      const featb4 = document.getElementById("featb4")
      
      runBottomOpacity(feat1)
      runOpacity(feat2, -40)

      runBottomOpacity(featb1, 0, -200)
      runBottomOpacity(featb2, 0, -180)
      runBottomOpacity(featb3, 0, -160)
      runBottomOpacity(featb4, 0, -140)

      const wm1 = document.getElementById("wm1")
      const wm2 = document.getElementById("wm2")
      const wmb1 = document.getElementById("wmb1")
      const wmb2 = document.getElementById("wmb2")
      const wmb3 = document.getElementById("wmb3")
      const wmb4 = document.getElementById("wmb4")
      const wmp = document.getElementById("wmp")
      const wmact = document.getElementById("wmact")
      const wm_fs1 = document.getElementById("wm_fs1")
      const wm_fs2 = document.getElementById("wm_fs2")
      
      runBottomOpacity(wm1)
      runOpacity(wm2, -40)

      if (width > 960) {
        runBottomOpacity(wmb1, 0, -100)
        runBottomOpacity(wmb2, 0, -80)
        runBottomOpacity(wmb3, 0, -60)
        runBottomOpacity(wmb4, 0, -40)
      } else {
        runBottomOpacity(wmb1, 0, -200)
        runBottomOpacity(wmb2, 0, -180)
        runBottomOpacity(wmb3, 0, -160)
        runBottomOpacity(wmb4, 0, -140)
      }

      runOpacity(wmp)
      runOpacity(wmact)
      runOpacity(wm_fs1, 300)
      runOpacity(wm_fs2, 250)

      const aus1 = document.getElementById("aus1")
      const aus2 = document.getElementById("aus2")
      const aus3 = document.getElementById("aus3")
      const aus4 = document.getElementById("aus4")
      const ausimg = document.getElementById("ausimg")

      runBottomOpacity(aus1)
      runOpacity(aus2, -40)
      runOpacity(aus3)
      runOpacity(aus4)
      runOpacity(ausimg, 300)
      
      const stor1 = document.getElementById("stor1")
      const stor2 = document.getElementById("stor2")
      const stor3 = document.getElementById("stor3")
      const stor4 = document.getElementById("stor4")
      const storb1 = document.getElementById("storb1")
      const storb2 = document.getElementById("storb2")
      const storb3 = document.getElementById("storb3")
      const storb4 = document.getElementById("storb4")
      const storbs = document.getElementById("storbs")

      runBottomOpacity(stor1)
      runOpacity(stor2, -40)
      runOpacity(stor3)
      runOpacity(stor4)
      if (width > 768) {
        runOpacity(storb1, 0, -400)
        runOpacity(storb2, 0, -380)
        runOpacity(storb3, 0, -360)
        runOpacity(storb4, 0, -340)
      } else {
        runOpacity(storbs, 300, 0)
      }

      const prods1 = document.getElementById("prods1")
      const prods2 = document.getElementById("prods2")
      const prod1 = document.getElementById("prod1")
      const prod2 = document.getElementById("prod2")
      const prod3 = document.getElementById("prod3")
      const prod4 = document.getElementById("prod4")
      const prod5 = document.getElementById("prod5")
      const prod6 = document.getElementById("prod6")
      const prod7 = document.getElementById("prod7")
      const prod8 = document.getElementById("prod8")
      const prod9 = document.getElementById("prod9")
      const prod10 = document.getElementById("prod10")

      runBottomOpacity(prods1)
      runOpacity(prods2, -40)

      runBottomOpacity(prod1, 0, -200 + 50)
      runBottomOpacity(prod2, 0, -180 + 50)
      runBottomOpacity(prod3, 0, -160 + 50)
      runBottomOpacity(prod4, 0, -140 + 50)
      runBottomOpacity(prod5, 0, -120 + 50)
      runBottomOpacity(prod6, 0, -100 + 50)
      runBottomOpacity(prod7, 0, -80 + 50)
      runBottomOpacity(prod8, 0, -60 + 50)
      runBottomOpacity(prod9, 0, -40 + 50)
      runBottomOpacity(prod10, 0, -20 + 50)
      
      const stps_ttl = document.getElementById("stps_ttl")
      const stps_act = document.getElementById("stps_act")
      const stps1 = document.getElementById("stps1")
      const stps2 = document.getElementById("stps2")
      const stps3 = document.getElementById("stps3")
      const stps4 = document.getElementById("stps4")
      const stps5 = document.getElementById("stps5")
      const stps6 = document.getElementById("stps6")
      const stps7 = document.getElementById("stps7")
      
      runBottomOpacity(stps_ttl)
      runOpacity(stps_act)
      
      runBottomOpacity(stps1, 0, -200 + 50)
      runBottomOpacity(stps2, 0, -180 + 50)
      runBottomOpacity(stps3, 0, -160 + 50)
      runBottomOpacity(stps4, 0, -140 + 50)

      runBottomOpacity(stps5, 0, -120 - 50)
      runBottomOpacity(stps6, 0, -100 - 50)
      runBottomOpacity(stps7, 0, -80 - 50)

      const arts_ttl = document.getElementById("arts_ttl")

      runBottomOpacity(arts_ttl)

      if (width > 768) {
        articles.forEach((element, ix) => {
          const art = document.getElementById(`art_${ix}`)
          if (art != null) {
            runBottomOpacity(art, 0, -150)
          }
        })
      } else {
        const art = document.getElementById(`arts`)
        runOpacity(art, 300, 0)
      }

      const ftr1 = document.getElementById("ftr1")
      const ftr2 = document.getElementById("ftr2")
      const ftr3 = document.getElementById("ftr3")
      const ftr4 = document.getElementById("ftr4")
      const ftr5 = document.getElementById("ftr5")
      const ftr6 = document.getElementById("ftr6")
      const ftrs1 = document.getElementById("ftrs1")
      const ftrs2 = document.getElementById("ftrs2")
      const ftrs3 = document.getElementById("ftrs3")
      const ftrs4 = document.getElementById("ftrs4")

      runBottomOpacity(ftr1)
      runOpacity(ftr2)
      runOpacity(ftr3)
      runOpacity(ftr4)
      runOpacity(ftr5)
      runOpacity(ftr6)

      runBottomOpacity(ftrs1, 0, -100)
      runBottomOpacity(ftrs2, 0, -80)
      runBottomOpacity(ftrs3, 0, -60)
      runBottomOpacity(ftrs4, 0, -40)

    }

    document.getElementById("root")!.addEventListener('scroll', onScroll)

    return () => {
      document.getElementById("root")!.removeEventListener('scroll', onScroll)
    }
  }, [])

  return (
    <>
      <section className={styles.head}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className={styles.float_block} id="float_block" style={{borderRadius: "0 100px"}}>
                <h2 id="fb_ttl2" style={{opacity: 0}}>Получи готовый рабочий бизнес по франшизе</h2>
                <h1 id="fb_ttl1" style={{opacity: 0, bottom: "-15px"}}>Открой магазин фермерских продуктов в своём городе</h1>

                <div id="fb_act" style={{opacity: 0}} className={styles.btns}>
                  <button onClick={() => openForm("Оставить заявку")}>Оставить заявку</button>
                </div>
              </div>
              <div className={styles.bottom_points}>
                <div className="container">
                  <div className="row">
                    <div className="col-12">
                      <div className={styles.bp_items}>
                        <div id="bpoint1" style={{opacity: 0, bottom: "-15px"}}>
                          <h3>от 500 тыс. р.</h3>
                          <p>Паушальный взнос</p>
                        </div>
                        <div id="bpoint2" style={{opacity: 0, bottom: "-15px"}}>
                          <h3>3%</h3>
                          <p>Роялти от выручки</p>
                        </div>
                        <div id="bpoint3" style={{opacity: 0, bottom: "-15px"}}>
                          <h3>от 15 мес. *</h3>
                          <p>Окупаемость</p>
                        </div>
                        <div id="bpoint4" style={{opacity: 0, bottom: "-15px"}}>
                          <h3>от 250 тыс. р.*</h3>
                          <p>Ежемесячная прибыль</p>
                        </div>
                      </div>
                      <div className={styles.bp_p}>
                        <p id="bpointp" style={{opacity: 0}}>* При соблюдении всех рекомендаций франчайзера</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={styles.about} id="about">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 id="about1">Франшиза "Фермерские истории"</h1>
              <h2 id="about2">для тех кто хочет зарабатывать, занимаясь любимым делом</h2>
              <p id="about3">Своя фермерская лавка - это доходный бизнес даже в условиях кризисной ситуации! Спрос на фермерские продукты постоянно растет. Продукты - это товары первой необходимости, а потребители все чаще выбирают экологичность и качество!</p>

              <div className={styles.blocks}>
                <div className={styles.b_item} id="abit1">
                  <div className={styles.ttl}>
                    <h4>Быстрый старт</h4>
                    <div className={styles.icon}><img src="/assets/images/home/about1.svg" /></div>
                  </div>
                  <p>Поможем подобрать помещение и дизайн магазина, оценим по точному алгоритму, спрогнозируем результат.<br/><br/><strong>Знаем сколько потребуется вложений и когда открытие</strong></p>
                </div>
                <div className={styles.b_item} id="abit2">
                  <div className={styles.ttl}>
                    <h4>Проверенный <br/>ассортимент</h4>
                    <div className={styles.icon}><img src="/assets/images/home/about2.svg" /></div>
                  </div>
                  <p>Мы предоставляем своих проверенных поставщиков и помогаем найти новых в вашем регионе.<br/><br/><strong>Расскажем где закупать и как продавать</strong></p>
                </div>
                <div className={styles.b_item} id="abit3">
                  <div className={styles.ttl}>
                    <h4>Логистика</h4>
                    <div className={styles.icon}><img src="/assets/images/home/about3.svg" /></div>
                  </div>
                  <p>Организуем и контролируем доставку товаров из Кубани в ваш регион.<br/><br/><strong>Знаем как доставить быстро и качественно</strong></p>
                </div>
                <div className={styles.b_item} id="abit4">
                  <div className={styles.ttl}>
                    <h4>Отработанные <br/>бизнес-процессы</h4>
                    <div className={styles.icon}><img src="/assets/images/home/about4.svg" /></div>
                  </div>
                  <p>Написали инструкции по всем процессам для каждого сотрудника<br/><br/><strong>Каждый партнер знает как действовать в разных ситуациях</strong></p>
                </div>
              </div>

              <div className={styles.xbtn} id="about_act">
                <button onClick={() => openForm("Узнать больше")}>Узнать больше</button>
              </div>
              
            </div>
          </div>
        </div>
      </section>

      <section className={styles.features}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 id="feat1">Сеть фермерских магазинов</h2>
              <h3 id="feat2">Каждый магазин пропитан настоящей деревенской атмосферой!</h3>

              <div className={styles.blocks}>
                <div className={styles.b_item + " " + styles.leaf} id="featb1">
                  <div className={styles.ttl}>
                    <div className={styles.num}>1</div>
                    <h4>Устойчивый <br/>спрос</h4>
                  </div>
                  <p>Продукты потребления всегда востребованы, даже в условиях кризиса</p>
                </div>
                <div className={styles.b_item + " " + styles.leaf} id="featb2">
                  <div className={styles.ttl}>
                    <div className={styles.num}>2</div>
                    <h4>Высокая <br/>рентабельность</h4>
                  </div>
                  <p>Откройте магазин с рентабельностью до 35% в год</p>
                </div>
                <div className={styles.b_item + " " + styles.leaf} id="featb3">
                  <div className={styles.ttl}>
                    <div className={styles.num}>3</div>
                    <h4>Проверенная <br/>модель</h4>
                  </div>
                  <p>Все этапы закупки и реализации проверены на практике.</p>
                </div>
                <div className={styles.b_item + " " + styles.leaf} id="featb4">
                  <div className={styles.ttl}>
                    <div className={styles.num}>4</div>
                    <h4>Дополнительная <br/>прибыль</h4>
                  </div>
                  <p>Доход от заказов через наш онлайн-сервис</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={styles.what_money}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 id="wm1">На чем мы зарабатываем?</h1>
              <h3 id="wm2">Оборот в месяц нашего магазина по франшизе:</h3>

              <div className={styles.blocks + " " + styles.simp}>
                <div className={styles.b_item + " " + styles.simple} id="wmb1">
                  <h4>от {width > 524 ? "1 500 000 руб." : "1.5 млн."}</h4>
                  <p>Оборот магазина</p>
                </div>
                <div className={styles.b_item + " " + styles.simple} id="wmb2">
                  <h4>1000 чел.</h4>
                  <p>Среднее кол-во покупателей</p>
                </div>
                <div className={styles.b_item + " " + styles.simple} id="wmb3">
                  <h4>1100 руб.</h4>
                  <p>Средний чек магазина</p>
                </div>
                <div className={styles.b_item + " " + styles.simple} id="wmb4">
                  <h4>3500 руб.</h4>
                  <p>Средний чек с сайта</p>
                </div>
              </div>

              <p className={styles.xtxt} id="wmp">Инвестиции в магазин всего до 3 500 000 руб. Это дешевле и выгоднее покупки авто!</p>

              <div className={styles.xbtn} id="wmact">
                <button onClick={() => openForm("Узнать больше")}>Узнать больше</button>
              </div>

              <div className={styles.flexed + " mt-6"}>
                <div className={styles.f_side} id="wm_fs1">
                  <div className={styles.leaf_card} style={{height: "315px"}}>
                    <h2>Создавай свою историю с нами!</h2>
                    <p>Сегодня мы помогаем фермерам найти своих покупателей в Краснодаре, Ростове и Москве.</p>
                    <p className={styles.quote}>«Мы делаем натуральные фермерские продукты<br/>доступными для каждого!»</p>

                    <div className={styles.socs}>
                      <a href="#"><i className="fab fa-instagram"></i></a>
                      <a href="#"><i className="fab fa-vk"></i></a>
                      <a href="#"><i className="fab fa-youtube"></i></a>
                      <a href="#"><i className="far fa-globe"></i></a>
                      <a href="#"><i className="fab fa-facebook"></i></a>
                    </div>
                  </div>
                </div>
                <div className={styles.f_side} id="wm_fs2">
                  <iframe width="100%" height="315" src="https://www.youtube.com/embed/Ty67_nlDrQ8?si=Yvy0f4DrzhrAA9C4" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={styles.about_us} id="about_us">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-5">
              <div className={styles.img}>
                <img id="ausimg" src="/assets/images/home/vit-1.jpg" />
              </div>
            </div>
            <div className="col-12 col-md-7">
              <h2 id="aus1">О нашем проекте</h2>
              <h3 id="aus2">Семейный бизнес</h3>
              <p id="aus3">25 лет наша семья занимается фермерством на Кубани. Первые магазины "Фермерские истории" создавались как семейный бизнес. Мы вложили всю душу в их создание, а наши покупатели полюбили и признали нас. Осознав, что наши магазины и продукция действительно нужны и любимы людьми, мы решили начать свое развитие по франшизе.</p>
              <p id="aus4" className={styles.quote}>«Создавая Фермерские истории мы стремились создать лучшие условия для взаимодействия фермеров и покупателей.» <strong>Виталий Рубашко, основатель сети</strong></p>
            </div>
          </div>
        </div>
      </section>

      <section className={styles.stories} id="features">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 id="stor1">Фермерские истории это надежность и экологичность</h1>
              <h3 id="stor2">«Мы решаем как проблемы фермеров, так и проблемы потребителей!»</h3>

              <div className={styles.points}>
                <div id="stor3">
                  <span><i className="fas fa-check"></i></span><p>Предоставляем качественную продукцию по адекватным ценам</p>
                </div>
                <div id="stor4">
                  <span><i className="fas fa-check"></i></span><p>Предоставляем возможность фермерам продавать свою продукцию через нас</p>
                </div>
              </div>

              <Swiper
                ref={swiperEl1}
                id="storbs"
                style={{marginTop: "24px"}}
                spaceBetween={20}
                modules={[Pagination, Navigation]}
                pagination={{ type: 'bullets', clickable: true }}
                slidesPerView={width > 768 ? (width > 1280 ? 4 : 3) : 1}
                onSwiper={(swiper) => setSwiper1((prev: any) => prev = swiper)}>
                <SwiperSlide>
                  <div className={styles.imaged_card} id="storb1">
                    <div className={styles.img}>
                      <img src="/assets/images/home/fs-11.jpeg" />
                    </div>
                    <h4>Уникальная <br/>продукция</h4>
                    <p>Экологичная и натуральная продукция от малых фермерских хозяйств и крестьянских подворий Кубани</p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className={styles.imaged_card} id="storb2">
                    <div className={styles.img}>
                      <img src="/assets/images/home/fs-22.jpeg" style={{
                        width: "134%",
                        position: "relative",
                        left: "-17%"
                      }} />
                    </div>
                    <h4>Качество и <br/>прозрачность продукта</h4>
                    <p>Продукты напрямую с фермы, без красителей и добавок выращеные в естественных условиях, прошедшие санитарный контроль и имеющие сертификаты.</p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className={styles.imaged_card} id="storb3">
                    <div className={styles.img}>
                      <img src="/assets/images/home/fs-3.jpg" />
                    </div>
                    <h4>Быстрая доставка</h4>
                    <p>Доставляем продукты своим транспортом с фермы сразу в магазин или к вашему порогу, минуя торговые склады.</p>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className={styles.imaged_card} id="storb4">
                    <div className={styles.img}>
                      <img src="/assets/images/home/fs-4.jpg" />
                    </div>
                    <h4>Сервис</h4>
                    <p>Быстрая и качественная работа — это основной приоритет наших сотрудников </p>
                  </div>
                </SwiperSlide>
              </Swiper>

            </div>
          </div>
        </div>
      </section>

      <section className={styles.products}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 id="prods1">Продукция сети "Фермерские истории"</h1>

              <div className={styles.prods}>
                <div id="prod1">
                  <img src="/assets/images/home/icon1.png" alt="Продукция сети 'Фермерские истории'" />
                  <p>Натуральные молочные продукты</p>
                </div>
                <div id="prod2">
                  <img src="/assets/images/home/icon2.png" alt="Продукция сети 'Фермерские истории'" />
                  <p>Охлажденное мясо и полуфабрикаты</p>
                </div>
                <div id="prod3">
                  <img src="/assets/images/home/icon3.png" alt="Продукция сети 'Фермерские истории'" />
                  <p>Колбасы и мясные деликатесы</p>
                </div>
                <div id="prod4">
                  <img src="/assets/images/home/icon4.png" alt="Продукция сети 'Фермерские истории'" />
                  <p>Овощи и фрукты</p>
                </div>
                <div id="prod5">
                  <img src="/assets/images/home/icon5.png" alt="Продукция сети 'Фермерские истории'" />
                  <p>Деревенские соления</p>
                </div>
                <div id="prod6">
                  <img src="/assets/images/home/icon6.png" alt="Продукция сети 'Фермерские истории'" />
                  <p>Деревенские яйца</p>
                </div>
                <div id="prod7">
                  <img src="/assets/images/home/icon7.png" alt="Продукция сети 'Фермерские истории'" />
                  <p>Хлеб и бакалея</p>
                </div>
                <div id="prod8">
                  <img src="/assets/images/home/icon8.png" alt="Продукция сети 'Фермерские истории'" />
                  <p>Кондитерские изделия</p>
                </div>
                <div id="prod9">
                  <img src="/assets/images/home/icon9.png" alt="Продукция сети 'Фермерские истории'" />
                  <p>Орехи и сухофрукты</p>
                </div>
                <div id="prod10">
                  <img src="/assets/images/home/icon10.png" alt="Продукция сети 'Фермерские истории'" />
                  <p>Мёд</p>
                </div>
              </div>

              <p className={styles.xtt2} id="prods2">Весь ассортимент продукции и информация о наших фермерах представлена на нашем <a href="https://fermstory.ru" target="_blank">сайте</a></p>
            </div>
          </div>
        </div>
      </section>

      <section className={styles.steps} id="steps">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 id="stps_ttl">7 шагов к собственному бизнесу</h1>

              <div className={styles.blocks + " " + styles.leaf_big}>
                <div className={styles.b_item + " " + styles.leaf} id="stps1">
                  <div className={styles.ttl}>
                    <div className={styles.num}>1</div>
                    <h4>Заявка <br/>на франшизу</h4>
                  </div>
                  <p>Оставьте заявку на сайте и получите полное описание франшизы и консультацию представителя компании.</p>
                </div>
                <div className={styles.b_item + " " + styles.leaf} id="stps2">
                  <div className={styles.ttl}>
                    <div className={styles.num}>2</div>
                    <h4>Встреча <br/>с менеджером</h4>
                  </div>
                  <p>Менеджер компании оказывает каждому партнеру поддержку на всех этапах организации бизнеса.</p>
                </div>
                <div className={styles.b_item + " " + styles.leaf} id="stps3">
                  <div className={styles.ttl}>
                    <div className={styles.num}>3</div>
                    <h4>Оценка помещения <br/>и клиентопотока</h4>
                  </div>
                  <p>Окажем помощь и предоставим рекомендации по подбору помещения согласно требованиям компании.</p>
                </div>
                <div className={styles.b_item + " " + styles.leaf} id="stps4">
                  <div className={styles.ttl}>
                    <div className={styles.num}>4</div>
                    <h4>Договора <br/>и стандарты</h4>
                  </div>
                  <p>Заключаем договор коммерческой концессии. Проводим обучение персонала стандартам ведения бизнеса.</p>
                </div>
              </div>

              <div className={styles.blocks + " " + styles.leaf_big + " " + styles.triple}>
                <div className={styles.b_item + " " + styles.leaf} id="stps5">
                  <div className={styles.ttl}>
                    <div className={styles.num}>5</div>
                    <h4>Подбор <br/>поставщиков</h4>
                  </div>
                  <p>Продумываем и налаживаем логистику, а также помогаем найти и проверяем местных поставщиков-фермеров.</p>
                </div>
                <div className={styles.b_item + " " + styles.leaf} id="stps6">
                  <div className={styles.ttl}>
                    <div className={styles.num}>6</div>
                    <h4>Ремонт помещения <br/>и открытие магазина</h4>
                  </div>
                  <p>Франчайзи получает дизайн-проект помещения с индивидуальными архитектурно-планировочными решениями.</p>
                </div>
                <div className={styles.b_item + " " + styles.leaf} id="stps7">
                  <div className={styles.ttl}>
                    <div className={styles.num}>7</div>
                    <h4>Доступ <br/>к онлайн-заказам</h4>
                  </div>
                  <p>Получаете доступ к онлайн заказам вашего города</p>
                </div>
              </div>

              <div className={styles.xbtn + " mt-6"} id="stps_act">
                <button onClick={() => openForm("Оставить заявку")}>Оставить заявку</button>
              </div>

            </div>
          </div>
        </div>
      </section>

      <section className={styles.articles}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 id="arts_ttl">Публикации о наc и нашей франшизе</h1>

              {width > 960 ? <div className={styles.posts}>
                {articles.map((article, ix) => 
                  <div className={styles.post_item + (ix % 2 == 0 ? "" : " " + styles.righted)} id={`art_${ix}`}>
                    <div className={styles.post_item__img}>
                      {article.image != null ? <img src={`${article.image}`} /> : <>
                        <iframe width="100%" height="315" src={article.frame} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                      </>}
                    </div>
                    <div className={styles.post_item__data}>
                      <h2>{article.title}</h2>
                      <h3>{article.subtitle}</h3>
                      <p style={width > 768 ? {} : {display: "none"}} dangerouslySetInnerHTML={{__html: article.text}}></p>
                      {article.source != null ? <a className={styles.source}><b>Источник:</b> {article.source}</a> : <></>}
                      {article.link != null ? <a className={styles.go} href={article.link} target="_blank">Читать статью</a> : <></>}
                    </div>
                  </div>)}
              </div> : <>
                <Swiper 
                  className={styles.posts} 
                  id="arts" 
                  modules={[Pagination, Navigation]}
                  pagination={{ type: 'bullets', clickable: true }}
                  slidesPerView={1} 
                  spaceBetween={20}>
                  {articles.map((article, ix) => <SwiperSlide>
                    <div className={styles.post_item + (ix % 2 == 0 ? "" : " " + styles.righted)} id={`art_${ix}`}>
                      <div className={styles.post_item__img}>
                        {article.image != null ? <img src={`${article.image}`} /> : <>
                          <iframe width="100%" height="315" src={article.frame} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                        </>}
                      </div>
                      <div className={styles.post_item__data}>
                        <h2>{article.title}</h2>
                        <h3>{article.subtitle}</h3>
                        <p style={width > 768 ? {} : {display: "none"}} dangerouslySetInnerHTML={{__html: article.text}}></p>
                        {article.source != null ? <a className={styles.source}><b>Источник:</b> {article.source}</a> : <></>}
                        {article.link != null ? <a className={styles.go} href={article.link} target="_blank">Читать статью</a> : <></>}
                      </div>
                    </div>
                  </SwiperSlide>)}
                </Swiper>
              </>}
            </div>
          </div>
        </div>
      </section>

      <section className={styles.contacts} id="contacts">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className={styles.con_wrap}>
                <div className={styles.con_side}>
                  <h1 id="ftr1">Контакты</h1>
                  <p id="ftr2">Наши магазины уже есть в Краснодаре,<br/>Ростове и Москве</p>
                  <a id="ftr3" className={styles.xa} href="tel:+79649293011"><span><i className="fas fa-phone"></i></span> +7 (964) 929-30-11</a>
                  <a id="ftr4" className={styles.xa} href="mailto:fermstory@gmail.com"><span><i className="fas fa-envelope"></i></span> fermstory@gmail.com</a>
                  <a id="ftr5" className={styles.xa} href="https://fermstory.ru"><span><i className="fas fa-globe"></i></span> fermstory.ru</a>

                  <div className={styles.socs}>
                    <a id="ftrs1" href="https://www.instagram.com/fermstory/"><i className="fab fa-instagram"></i></a>
                    <a id="ftrs2" href="https://vk.com/fermstory"><i className="fab fa-vk"></i></a>
                    <a id="ftrs3" href="https://www.youtube.com/channel/UC4PT5xWBkXFS3PBf6362IHw"><i className="fab fa-youtube"></i></a>
                    <a id="ftrs4" href="https://www.facebook.com/fermstory.ru/"><i className="fab fa-facebook"></i></a>
                  </div>

                  <button id="ftr6" onClick={() => openForm("Получить консультацию")}>Получить консультацию</button>
                </div>
                <div className={styles.con_side}>
                  <YMaps>
                    <Map width="100%" height="100%" defaultState={{ center: [50.75, 37.57], zoom: 4 }}>
                      <ZoomControl options={{ position: {right: "16px", top: "16px"} }} />
                      <Clusterer
                        options={{
                          preset: "islands#invertedGreyClusterIcons",
                          groupByCoordinates: false,
                        }}>
                        {clusterPoints.map((coordinates, index) => (
                          <Placemark key={index} options={{iconColor: "#6d8e03"}} geometry={coordinates} />
                        ))}
                      </Clusterer>
                    </Map>
                  </YMaps>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Modal
        visible={store.isFormVisible}
        title={store.formTitle}
        onClose={() => store.setIsFormVisible(false)}
        content={<>
          <div className="modal_text">
            <p>Оставьте заявку, мы перезвоним в ближайшее время и ответим на все вопросы</p>

            <div className={styles.xform}>
              <div className={styles.xform_item}>
                <label htmlFor="name">Ваше имя</label>
                <input type="text" placeholder="Иван Иванов" value={userName} onChange={(evt) => setUserName(evt.target.value)} />
              </div>
              <div className={styles.xform_item}>
                <label htmlFor="name">Ваш город</label>
                <input type="text" placeholder="Москва" value={userCity} onChange={(evt) => setUserCity(evt.target.value)} />
              </div>
              <div className={styles.xform_item}>
                <label htmlFor="name">Ваш телефон</label>
                <InputMask mask="+7 999 999 99 99" maskChar="_" placeholder="+7 999 999-06-99" value={userPhone} onChange={(event) => setUserPhone(event.target.value)} />
              </div>
            </div>

            <ReCAPTCHA
              sitekey="6LdNxaEpAAAAABizKgIQ_1qjFMydLRi-LyiOorMs"
              onChange={(token) => {
                console.log(token)
                setUserToken(`${token}`)
              }}
              onExpired={() => {
                console.log("expired")
                setUserToken("")
              }} />
          </div>
          <div className="modal_action">
            <div>
              <button disabled={userToken == ""} onClick={() => sendForm()}>Отправить</button>
            </div>
          </div>
        </>} />
    </>
  )
}

export default observer(Home)