import React from "react";

import Home from "../../pages/Home"
import Policy from "../../pages/Policy"

export interface IRoute {
  path: string;
  component: React.ReactNode;
  exact?: boolean;
}

export enum RouteNames {
  HOME = '/',
  POLICY = '/policy'
}

export const publicRoutes: IRoute[ ] = [
  { path: RouteNames.HOME, exact: true, component: <Home /> },
  { path: RouteNames.POLICY, exact: true, component: <Policy /> },
]
