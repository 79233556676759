import { FC, useContext, useEffect } from 'react';
import { observer } from "mobx-react-lite";
import { Context } from '.';
import { useLocation } from 'react-router-dom';
import useWindowDimensions from './core/hooks/useWindowDimensions';
import AppRouter from './components/AppRouter';

import './styles/App.scss';
import Footer from './components/Footer';
import Header from './components/Header';

const App: FC = () => {

  const { store, adminStore } = useContext(Context)

  const location = useLocation()
  
  const { width, height } = useWindowDimensions()

  useEffect(() => {
    if (width <= 768) {
      store.setIsMobile(true)
    } else {
      store.setIsMobile(false)
    }
  }, [width, height])

  useEffect(() => {
    if (width <= 768) {
      store.setIsMobile(true);
    } else {
      store.setIsMobile(false);
    }
  }, [])

  return (
    <>
      <Header />
      <AppRouter />
      <Footer />
    </>
  )
}

export default observer(App)
