import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { API_URL } from "../utils/defaults";
import { isNullOrUndefined } from "../utils/utils";

const $api = axios.create({
  baseURL: API_URL
});

$api.interceptors.request.use((config: AxiosRequestConfig) => {
  config.headers!["Authorization"] = `Bearer ${localStorage.getItem("user_token")}`;
  return config;
});

$api.interceptors.response.use((response: AxiosResponse) => {
  return response;
}, async (error: any) => {
  console.log("INTERCEPT", error.response.data.message);
  // localStorage.clear()
  // window.location.reload()
  return error;
});

export default $api;